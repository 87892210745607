@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;700&display=swap');
@import 'normalize';
@import 'btn';
@import 'checkboxes';

$btn-color: rgb(165, 1, 116);

body {
  background-color: #210464;
  overflow: hidden;
  font-family: 'Ubuntu', sans-serif;
  line-height: 1.5;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  line-height: 2;
  color: rgb(8, 15, 44);
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.main-bg {
  z-index: -2;
  position: absolute;
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  top: -20px;
  left: -20px;
  background-image: url("../img/04.jpg?as=jpeg&width=100&quality=15");
  background-blend-mode: darken;
  background-size: cover;
  filter: blur(20);
  opacity: .4;
}

.img {
  // width: calc(100% + 40px);
  width: 70%;
  aspect-ratio: 1/0.9;
  display: block;
  position: relative;
  background-color: #fff;
  background-size: cover;
  background-position: center;
  // background-image: url("../img/shy1.jpg?as=jpeg&width=350&quality=55");
  // background-image: image-set(
  //   url("../img/01.jpg?as=avif&width=400&quality=55") 1x type("image/avif"),
  //   url("../img/01.jpg?as=jpeg&width=400&quality=55") 1x type("image/jpeg")
  // );
  border-radius: 46% 54% 52% 48% / 33% 63% 37% 67%;
  margin: 0 auto;
  transition: background-image 0.01s ease-in;

  &.s2 {
    background-image: url("../img/s2/03.jpg?as=jpeg&width=650&quality=55");
    background-position: 20% -85%;
    background-size: 160%;

    &.animate {
      background-image: url("../img/s2/gif3.gif");
      background-position: 0% 0%;
      background-size: 100%;
    }

    &.animate2 {
      background-image: url("../img/s2/gif4.gif");
      background-position: 0% 0%;
      background-size: 100%;
    }
  }

  &.s3 {
    background-image: url("../img/s2/04.jpg?as=jpeg&quality=55") !important;
    background-position: center !important;
    background-size: 100% !important;

    &.animate {
      background-image: url("../img/s2/gif51.gif") !important;
      background-position: 0% 0%;
      background-size: 100%;
    }
  }

  &.s4 {
    background-position: right !important;
    background-size: 105% !important;

    &.i1{
      background-image: url("../img/s4/1.jpg?as=jpeg&width=350&quality=55") !important;
    }

    &.i2{
      background-image: url("../img/s4/2.jpg?as=jpeg&width=350&quality=55") !important;
    }

    &.i4 {
      background-position: center !important;
      background-size: 100% !important;
      background-image: url("../img/s4/4.jpg?as=jpeg&width=350&quality=55") !important;
    }

    &.i5 {
      background-position: center !important;
      background-size: 105% !important;
      background-image: url("../img/s4/13.jpg?as=jpeg&width=450&quality=65") !important;
      aspect-ratio: 1/1.34 !important;
      background-position: 70% 70% !important;
    }

    &.i6 {
      background-position: center !important;
      background-size: 100% !important;
      background-image: url("../img/s4/6.jpg?as=jpeg&width=350&quality=55") !important;
    }

    &.i7 {
      background-size: 113% !important;
      background-position: -60% 40% !important;
      background-image: url("../img/s4/7.jpg?as=jpeg&width=450&quality=55") !important;
      // border-radius: 0% !important;
      aspect-ratio: 1/1.3 !important;
      // box-shadow: 0px 0px 48px 5px rgba(245, 31, 156, 0.35);
    }

    &.i8 {
      background-size: 123% !important;
      background-position:50% 20% !important;
      background-image: url("../img/s4/15.jpg?as=jpeg&width=550&quality=65") !important;
      border-radius: 30% 5% 30% 5% !important;
      aspect-ratio: 1/1.2 !important;
      // box-shadow: 0px 0px 48px 5px rgba(245, 31, 156, 0.35);
    }

    &.i9 {
      background-size: 123% !important;
      background-position: 40% 40% !important;
      background-image: url("../img/s4/11.jpg?as=jpeg&width=450&quality=55") !important;
      // border-radius: 0% !important;
      aspect-ratio: 1/1 !important;
      // box-shadow: 0px 0px 48px 5px rgba(245, 31, 156, 0.35);
    }

    &.i16 {
      background-size: 123% !important;
      background-position:50% 20% !important;
      background-image: url("../img/s4/16.jpg?as=jpeg&width=550&quality=65") !important;
      // border-radius: 30% 5% 30% 5% !important;
      aspect-ratio: 1/1.2 !important;
      // box-shadow: 0px 0px 48px 5px rgba(245, 31, 156, 0.35);
    }

    &.i17 {
      background-size: 140% !important;
      background-position: 20% 45% !important;
      background-image: url("../img/s4/17.jpg?as=jpeg&width=550&quality=65") !important;
      // border-radius: 30% 5% 30% 5% !important;
      aspect-ratio: 1/1.4 !important;
      // box-shadow: 0px 0px 48px 5px rgba(245, 31, 156, 0.35);
    }

    &.i18 {
      background-size: 160% !important;
      background-position: 45% 76% !important;
      background-image: url("../img/s4/18.jpg?as=jpeg&width=550&quality=65") !important;
      // border-radius: 30% 5% 30% 5% !important;
      aspect-ratio: 1/1.2 !important;
      // box-shadow: 0px 0px 48px 5px rgba(245, 31, 156, 0.35);
    }

    &.i19 {
      background-size: 100% !important;
      background-position: 5% 50% !important;
      background-image: url("../img/s4/19.jpg?as=jpeg&width=550&quality=65") !important;
      // border-radius: 45% 45% 45% 45% !important;
      aspect-ratio: 1/1.35 !important;
      // box-shadow: 0px 0px 48px 5px rgba(245, 31, 156, 0.35);
    }

    &.i20 {
      background-size: 105% !important;
      background-position: 5% 20% !important;
      background-image: url("../img/s4/20.jpg?as=jpeg&width=550&quality=65") !important;
      // border-radius: 45% 45% 45% 45% !important;
      aspect-ratio: 1/1.38 !important;
      // box-shadow: 0px 0px 48px 5px rgba(245, 31, 156, 0.35);
    }

    &.i21 {
      background-size: 145% !important;
      background-position: 45% 39% !important;
      background-image: url("../img/shy1.jpg?as=jpeg&width=650&quality=75") !important;
      // border-radius: 45% 45% 45% 45% !important;
      aspect-ratio: 1/1.14 !important;
      // box-shadow: 0px 0px 48px 5px rgba(245, 31, 156, 0.35);
    }

    &.i22 {
      background-size: 101% !important;
      background-position: 45% 89% !important;
      background-image: url("../img/s4/22.jpg?as=jpeg&width=650&quality=75") !important;
      border-radius: 45% 0% 45% 30% !important;
      aspect-ratio: 1/1.6 !important;
      // box-shadow: 0px 0px 48px 5px rgba(245, 31, 156, 0.35);
    }

    &.i23 {
      background-size: 231% !important;
      background-position: 55% 69% !important;
      background-image: url("../img/s4/23.jpg?as=jpeg&width=650&quality=75") !important;
      // border-radius: 45% 0% 45% 30% !important;
      aspect-ratio: 1/1.2 !important;
      // box-shadow: 0px 0px 48px 5px rgba(245, 31, 156, 0.35);
    }

    &.i24 {
      background-size: 105% !important;
      background-position: 45% 90% !important;
      background-image: url("../img/s4/24.jpg?as=jpeg&width=650&quality=75") !important;
      // border-radius: 45% 0% 45% 30% !important;
      aspect-ratio: 1/1.2 !important;
      // box-shadow: 0px 0px 48px 5px rgba(245, 31, 156, 0.35);
    }

    &.i25 {
      background-size: 115% !important;
      background-position: 45% 90% !important;
      background-image: url("../img/s4/25.jpg?as=jpeg&width=650&quality=75") !important;
      // border-radius: 45% 0% 45% 30% !important;
      aspect-ratio: 1/1.2 !important;
      // box-shadow: 0px 0px 48px 5px rgba(245, 31, 156, 0.35);
    }

    &.i26 {
      background-size: 125% !important;
      background-position: 35% 50% !important;
      background-image: url("../img/s4/26.jpg?as=jpeg&width=650&quality=75") !important;
      // border-radius: 45% 0% 45% 30% !important;
      aspect-ratio: 1/1.2 !important;
      // box-shadow: 0px 0px 48px 5px rgba(245, 31, 156, 0.35);
    }

    &.i27 {
      background-size: 128% !important;
      background-position: 0% 75% !important;
      background-image: url("../img/s4/27.jpg?as=jpeg&width=650&quality=75") !important;
      border-radius: 45% 30% 45% 30% !important;
      aspect-ratio: 1/1.3 !important;
      // box-shadow: 0px 0px 48px 5px rgba(245, 31, 156, 0.35);
    }

    &.i28 {
      background-size: 102% !important;
      background-position: 0% 85% !important;
      background-image: url("../img/s4/28.jpg?as=jpeg&width=650&quality=75") !important;
      border-radius: 45% 30% 45% 30% !important;
      aspect-ratio: 1/1.3 !important;
      // box-shadow: 0px 0px 48px 5px rgba(245, 31, 156, 0.35);
    }

    &.i29 {
      background-size: 122% !important;
      background-position: 0% 100% !important;
      background-image: url("../img/s4/29.jpg?as=jpeg&width=650&quality=75") !important;
      border-radius: 35% 30% 35% 30% !important;
      aspect-ratio: 1/1.2 !important;
      // box-shadow: 0px 0px 48px 5px rgba(245, 31, 156, 0.35);
    }

    &.i30 {
      background-size: 102% !important;
      background-position: 50% 60% !important;
      background-image: url("../img/s4/30.jpg?as=jpeg&width=650&quality=75") !important;
      // border-radius: 35% 30% 35% 30% !important;
      aspect-ratio: 1/1.3 !important;
      // box-shadow: 0px 0px 48px 5px rgba(245, 31, 156, 0.35);
    }

    &.i31 {
      background-size: 102% !important;
      background-position: 0% 50% !important;
      background-image: url("../img/s4/31.jpg?as=jpeg&width=650&quality=75") !important;
      border-radius: 0% !important;
      aspect-ratio: 1/1.05 !important;
      box-shadow: 0px 0px 48px 5px rgba(245, 31, 156, 0.35);
      width: 90% !important;
      // left: -20px;
    }

    &.i32 {
      background-size: 145% !important;
      background-position: 50% 80% !important;
      background-image: url("../img/s4/32.jpg?as=jpeg&width=650&quality=75") !important;
      border-radius: 0% !important;
      aspect-ratio: 1/1.05 !important;
      box-shadow: 0px 0px 48px 5px rgba(245, 31, 156, 0.35);
      width: 90% !important;
      // left: -20px;
    }

    &.i33 {
      background-size: 100% !important;
      background-position: 50% 50% !important;
      background-image: url("../img/s4/33.jpg?as=jpeg&width=650&quality=75") !important;
      border-radius: 0% !important;
      aspect-ratio: 1/1.05 !important;
      box-shadow: 0px 0px 48px 5px rgba(245, 31, 156, 0.35);
      width: 90% !important;
      // left: -20px;
    }

    &.i34 {
      background-size: 105% !important;
      background-position: 50% 50% !important;
      background-image: url("../img/s4/34.jpg?as=jpeg&width=650&quality=75") !important;
      border-radius: 0% !important;
      aspect-ratio: 1/1.05 !important;
      box-shadow: 0px 0px 48px 5px rgba(245, 31, 156, 0.35);
      width: 90% !important;
      // left: -20px;
    }

    &.i35 {
      background-size: 105% !important;
      background-position: 50% 50% !important;
      background-image: url("../img/s4/35.jpg?as=jpeg&width=650&quality=75") !important;
      border-radius: 0% !important;
      aspect-ratio: 1/1.05 !important;
      box-shadow: 0px 0px 48px 5px rgba(245, 31, 156, 0.35);
      width: 90% !important;
      // left: -20px;
    }
  }
  
  
  
  // @keyframes girlplay {
  //   from, to {
  //     background-size: 100%;
  //     background-position: 20% 50%;
  //     background-image: url("../img/s2/gif.gif");
  //   }
  // }


  &.s1 {
    background-image: url("../img/s1/site_1.jpg?as=jpeg&width=350&quality=55");

    &.i2 {
      background-image: url("../img/s1/site_2.jpg?as=jpeg&width=350&quality=55");
    }

    &.i3 {
      background-image: url("../img/s1/site_3.jpg?as=jpeg&width=350&quality=55");
    }

    &.i4 {
      background-image: url("../img/s1/site_4.jpg?as=jpeg&width=350&quality=55");
    }
  }
}

.img2 {
  width: 175px;
  aspect-ratio: 1/1;
  display: block;
  background-color: #fff;
  background-size: cover;
  background-position: top;
  margin: 0 auto;
  transition: background-image 0.1s ease-in;
  position: relative;

  &.privacy {
    border-radius: 46% 54% 52% 48% / 33% 63% 37% 67%;
    background-image: url("../img/shh2.jpg?as=jpeg&width=200&quality=85");
  }

  .scenes {
    position: absolute;
    top: 0;
    left: 0;
    height: 175px;
    border-radius: 46% 54% 52% 48% / 33% 63% 37% 67%;
    scale: 1.01;

    &.scene1 {
      width: 175px;
      background-size: 100%;
      background-position: 20% 50%;
      background-image: url("../img/s2/gif.gif");
    }

    &.scene2 {
      width: 175px;
      background-size: 120%;
      background-position: 20% 50%;
      background-image: url("../img/s2/gif2.gif");
    }
  }
}

.hidden {
  display: none;
}

.steps {
  margin-top: 0px;
  display: flex;
  width: 100vw;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  div {
    position: relative;
    display: block;
    height: 20px;
    background-color: rgba(darken($btn-color, 20%), .3);
    margin: 0px;
    
    width: 100%;

    &.active {
      background-color: $btn-color;

      &.step_no {
        background-color: rgba(darken($btn-color, 20%), .3);
        &::before {
          position: absolute;
          height: 100%;
          width: 0%;
          background-color: $btn-color;
          top:0;
          left: 0;
          content: '';
          animation: fill-progress-bar 200ms cubic-bezier(0.25,0.1,0.25,1) both;
        }
      }
    }
  }
}


/**
 * ----------------------------------------
 * animation puff-in-center
 * ----------------------------------------
 */
 @keyframes fill-progress-bar {
  0% {
    width: 0%;
  }

  60% {
    width: 115%;
  }

  85% {
    width: 95%;
  }

  100% {
    width: 100%;
  }
}

#fx, #bg {
  z-index: -1;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.2;
  mix-blend-mode:hard-light;
  transition: all 0.3s ease-in-out;
}

#bg {
  filter: blur(30);
  opacity: 0.4;

  &.v2 {
    filter: blur(5);
  }
}


main {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;

  .box {
    text-align: center;
    font-size: 18px;
    background-color: #111;
    color: white;
    margin: 0 20px;
    margin-top: 0px;
    padding: 15px 20px;
    border-radius: 1.5em;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.4);
    width: 92%;
    min-width: 265px;
    max-width: 570px;
    
    h1 {
      margin-top: 0px;
      margin-bottom: 5px;
      font-weight: 700;
      font-size: 21px;
    }

    h2,h3 {
      font-weight: 300;
      font-size: 17px;
    }

    form {
      margin: 40px 0;
      min-height: 0;
      text-align: left;
    }

  
    @media only screen and (min-width: 769px) {
      padding: 35px 40px;

      h1 {
        font-size: 26px;
        margin-bottom: 15px;
      }

      h2,h3 {
        font-weight: 300;
        font-size: 19px;
      }
  
      // aspect-ratio: 11/16;
    }
  }
}

.m-wrapper {
  // border: 1px solid #fff;
  z-index: 5;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  .m-wrapper {
    justify-content: flex-start;
    margin-top: 5vh;
  }

  main {
    width: 100%;
  }
}

@media only screen and (min-width: 769px) {
  /* For mobile phones: */
  main {
    aspect-ratio: 11/16;
  }
}


.puff-in-center {
	animation: puff-in-center 80ms cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
}

/**
 * ----------------------------------------
 * animation puff-in-center
 * ----------------------------------------
 */
 @keyframes puff-in-center {
  0% {
    transform: scale(0.92);
    // filter: blur(18px);
    opacity: 0.1;
  }
  100% {
    transform: scale(1);
    // filter: blur(0px);
    opacity: 1;
  }
}

.w1 {
  outline-color: #23124dd8;
  outline-width: 3px;
  outline-style: solid;
  padding: 12px;
}

.w2 {
  color: rgba(223, 49, 156, 0.541);
  padding: 8px 0;
  font-weight: 400;
  font-size: 19px;
  text-transform: uppercase;
}

.larger {
  font-size: 36px !important;
}