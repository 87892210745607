@import "https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;700&display=swap";
html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  line-height: 1.15;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

figcaption, figure, main {
  display: block;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: inherit;
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  color: #000;
  background-color: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
  display: inline-block;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details, menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template, [hidden] {
  display: none;
}

.btn-wrapper {
  grid-auto-flow: column;
  gap: 30px;
  display: grid;
}

@media only screen and (width <= 768px) {
  .btn-wrapper {
    gap: 20px;
  }
}

.btn {
  cursor: pointer;
  color: #fff;
  letter-spacing: .05rem;
  text-shadow: 0 1px #67085a;
  background-color: #c70eae;
  border-radius: 15px;
  width: 100%;
  margin: 20px 0 0;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 21px;
  font-weight: 700;
  text-decoration: none;
  display: inline-block;
  position: relative;
  box-shadow: 0 10px 35px -10px #970b84;
  outline: 0 !important;
}

.btn.cta-btn:before {
  content: "";
  z-index: -1;
  filter: blur(5px);
  opacity: 1;
  background: linear-gradient(45deg, #5600ff, #7a00ff, #0095ff, #ff0080, #ee05e2, #002bff, #7a00ff, #7800c4, #5d00c4) 0 0 / 400%;
  border-radius: 20px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  transition: opacity .4s ease-in-out;
  animation: 40s linear infinite glowing;
  position: absolute;
  top: -1px;
  left: -1px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.btn:hover {
  background-color: #ee1ad1;
}

.btn:active, .btn:focus {
  outline: 0;
  position: relative;
  top: 1px;
}

.btn.skip {
  text-shadow: 0 1px #2e2b342a;
  background-color: #60596c2a;
  box-shadow: 0 10px 35px -10px #4742502a;
}

.btn.skip:hover {
  background-color: #7970882a;
}

:root {
  --form-control-color: rebeccapurple;
  --form-control-disabled: #959495;
}

*, :before, :after {
  box-sizing: border-box;
}

body {
  margin: 0;
}

form {
  place-content: center;
  min-height: 100vh;
  display: grid;
}

.form-control {
  grid-template-columns: 1.5em auto;
  gap: .7em;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.5;
  display: grid;
}

.form-control + .form-control {
  margin-top: 1.1em;
}

.form-control--disabled {
  color: var(--form-control-disabled);
  cursor: not-allowed;
}

input[type="checkbox"] {
  appearance: none;
  background-color: var(--form-background);
  font: inherit;
  color: currentColor;
  border: .15em solid;
  border-radius: .15em;
  place-content: center;
  width: 1.25em;
  height: 1.25em;
  margin: 0;
  display: grid;
  transform: translateY(.1em);
}

input[type="checkbox"]:before {
  content: "";
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform-origin: 0 100%;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: canvastext;
  width: .65em;
  height: .65em;
  transition: transform 70ms ease-in-out;
  transform: scale(0);
}

input[type="checkbox"]:checked:before {
  transform: scale(1);
}

input[type="checkbox"]:focus {
  outline-offset: max(1px, .1em);
  outline: max(1px, .1em) solid #ffffff1f;
}

input[type="checkbox"]:disabled {
  --form-control-color: var(--form-control-disabled);
  color: var(--form-control-disabled);
  cursor: not-allowed;
}

body {
  color: #080f2c;
  background-color: #210464;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
  line-height: 2;
  overflow: hidden;
}

.wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
  position: relative;
}

.main-bg {
  z-index: -2;
  background-blend-mode: darken;
  filter: blur(20px);
  opacity: .4;
  background-image: url("../../04.c146a027.jpeg");
  background-size: cover;
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  position: absolute;
  top: -20px;
  left: -20px;
}

.img {
  aspect-ratio: 1 / .9;
  background-color: #fff;
  background-position: center;
  background-size: cover;
  border-radius: 46% 54% 52% 48% / 33% 63% 37% 67%;
  width: 70%;
  margin: 0 auto;
  transition: background-image 10ms ease-in;
  display: block;
  position: relative;
}

.img.s2 {
  background-image: url("../../03.3d6c2e79.jpeg");
  background-position: 20% -85%;
  background-size: 160%;
}

.img.s2.animate {
  background-image: url("../../gif3.19a3e8dc.gif");
  background-position: 0 0;
  background-size: 100%;
}

.img.s2.animate2 {
  background-image: url("../../gif4.f275652a.gif");
  background-position: 0 0;
  background-size: 100%;
}

.img.s3 {
  background-image: url("../../04.2d8abece.jpeg") !important;
  background-position: center !important;
  background-size: 100% !important;
}

.img.s3.animate {
  background-position: 0 0;
  background-size: 100%;
  background-image: url("../../gif51.6d5bec84.gif") !important;
}

.img.s4 {
  background-position: 100% !important;
  background-size: 105% !important;
}

.img.s4.i1 {
  background-image: url("../../1.8fa45a28.jpeg") !important;
}

.img.s4.i2 {
  background-image: url("../../2.62de312b.jpeg") !important;
}

.img.s4.i4 {
  background-image: url("../../4.d3f30b3b.jpeg") !important;
  background-position: center !important;
  background-size: 100% !important;
}

.img.s4.i5 {
  aspect-ratio: 1 / 1.34 !important;
  background-image: url("../../13.f22de129.jpeg") !important;
  background-position: 70% 70% !important;
  background-size: 105% !important;
}

.img.s4.i6 {
  background-image: url("../../6.3b026c02.jpeg") !important;
  background-position: center !important;
  background-size: 100% !important;
}

.img.s4.i7 {
  aspect-ratio: 1 / 1.3 !important;
  background-image: url("../../7.e2757f65.jpeg") !important;
  background-position: -60% 40% !important;
  background-size: 113% !important;
}

.img.s4.i8 {
  aspect-ratio: 1 / 1.2 !important;
  background-image: url("../../15.7b36e623.jpeg") !important;
  background-position: 50% 20% !important;
  background-size: 123% !important;
  border-radius: 30% 5% !important;
}

.img.s4.i9 {
  aspect-ratio: 1 !important;
  background-image: url("../../11.7d37dcdf.jpeg") !important;
  background-position: 40% 40% !important;
  background-size: 123% !important;
}

.img.s4.i16 {
  aspect-ratio: 1 / 1.2 !important;
  background-image: url("../../16.d9ed5751.jpeg") !important;
  background-position: 50% 20% !important;
  background-size: 123% !important;
}

.img.s4.i17 {
  aspect-ratio: 1 / 1.4 !important;
  background-image: url("../../17.c638bcbd.jpeg") !important;
  background-position: 20% 45% !important;
  background-size: 140% !important;
}

.img.s4.i18 {
  aspect-ratio: 1 / 1.2 !important;
  background-image: url("../../18.ff1b5194.jpeg") !important;
  background-position: 45% 76% !important;
  background-size: 160% !important;
}

.img.s4.i19 {
  aspect-ratio: 1 / 1.35 !important;
  background-image: url("../../19.eb08f514.jpeg") !important;
  background-position: 5% !important;
  background-size: 100% !important;
}

.img.s4.i20 {
  aspect-ratio: 1 / 1.38 !important;
  background-image: url("../../20.146461da.jpeg") !important;
  background-position: 5% 20% !important;
  background-size: 105% !important;
}

.img.s4.i21 {
  aspect-ratio: 1 / 1.14 !important;
  background-image: url("../../shy1.ce2fa78c.jpeg") !important;
  background-position: 45% 39% !important;
  background-size: 145% !important;
}

.img.s4.i22 {
  aspect-ratio: 1 / 1.6 !important;
  background-image: url("../../22.acd814cc.jpeg") !important;
  background-position: 45% 89% !important;
  background-size: 101% !important;
  border-radius: 45% 0% 45% 30% !important;
}

.img.s4.i23 {
  aspect-ratio: 1 / 1.2 !important;
  background-image: url("../../23.d3d0449d.jpeg") !important;
  background-position: 55% 69% !important;
  background-size: 231% !important;
}

.img.s4.i24 {
  aspect-ratio: 1 / 1.2 !important;
  background-image: url("../../24.88aa2c07.jpeg") !important;
  background-position: 45% 90% !important;
  background-size: 105% !important;
}

.img.s4.i25 {
  aspect-ratio: 1 / 1.2 !important;
  background-image: url("../../25.e76f7232.jpeg") !important;
  background-position: 45% 90% !important;
  background-size: 115% !important;
}

.img.s4.i26 {
  aspect-ratio: 1 / 1.2 !important;
  background-image: url("../../26.78df9906.jpeg") !important;
  background-position: 35% !important;
  background-size: 125% !important;
}

.img.s4.i27 {
  aspect-ratio: 1 / 1.3 !important;
  background-image: url("../../27.444e6a26.jpeg") !important;
  background-position: 0 75% !important;
  background-size: 128% !important;
  border-radius: 45% 30% !important;
}

.img.s4.i28 {
  aspect-ratio: 1 / 1.3 !important;
  background-image: url("../../28.ce0f352f.jpeg") !important;
  background-position: 0 85% !important;
  background-size: 102% !important;
  border-radius: 45% 30% !important;
}

.img.s4.i29 {
  aspect-ratio: 1 / 1.2 !important;
  background-image: url("../../29.2cc95ed5.jpeg") !important;
  background-position: 0 100% !important;
  background-size: 122% !important;
  border-radius: 35% 30% !important;
}

.img.s4.i30 {
  aspect-ratio: 1 / 1.3 !important;
  background-image: url("../../30.f81f08f4.jpeg") !important;
  background-position: 50% 60% !important;
  background-size: 102% !important;
}

.img.s4.i31 {
  box-shadow: 0 0 48px 5px #f51f9c59;
  aspect-ratio: 1 / 1.05 !important;
  background-image: url("../../31.186f86e8.jpeg") !important;
  background-position: 0% !important;
  background-size: 102% !important;
  border-radius: 0% !important;
  width: 90% !important;
}

.img.s4.i32 {
  box-shadow: 0 0 48px 5px #f51f9c59;
  aspect-ratio: 1 / 1.05 !important;
  background-image: url("../../32.45fd8809.jpeg") !important;
  background-position: 50% 80% !important;
  background-size: 145% !important;
  border-radius: 0% !important;
  width: 90% !important;
}

.img.s4.i33 {
  box-shadow: 0 0 48px 5px #f51f9c59;
  aspect-ratio: 1 / 1.05 !important;
  background-image: url("../../33.1c8319ee.jpeg") !important;
  background-position: 50% !important;
  background-size: 100% !important;
  border-radius: 0% !important;
  width: 90% !important;
}

.img.s4.i34 {
  box-shadow: 0 0 48px 5px #f51f9c59;
  aspect-ratio: 1 / 1.05 !important;
  background-image: url("../../34.c6d39555.jpeg") !important;
  background-position: 50% !important;
  background-size: 105% !important;
  border-radius: 0% !important;
  width: 90% !important;
}

.img.s4.i35 {
  box-shadow: 0 0 48px 5px #f51f9c59;
  aspect-ratio: 1 / 1.05 !important;
  background-image: url("../../35.a6a91f89.jpeg") !important;
  background-position: 50% !important;
  background-size: 105% !important;
  border-radius: 0% !important;
  width: 90% !important;
}

.img.s1 {
  background-image: url("../../site_1.9541eb19.jpeg");
}

.img.s1.i2 {
  background-image: url("../../site_2.12ae2c46.jpeg");
}

.img.s1.i3 {
  background-image: url("../../site_3.a9a917db.jpeg");
}

.img.s1.i4 {
  background-image: url("../../site_4.28eef70e.jpeg");
}

.img2 {
  aspect-ratio: 1;
  background-color: #fff;
  background-position: top;
  background-size: cover;
  width: 175px;
  margin: 0 auto;
  transition: background-image .1s ease-in;
  display: block;
  position: relative;
}

.img2.privacy {
  background-image: url("../../shh2.01c93dd6.jpeg");
  border-radius: 46% 54% 52% 48% / 33% 63% 37% 67%;
}

.img2 .scenes {
  border-radius: 46% 54% 52% 48% / 33% 63% 37% 67%;
  height: 175px;
  position: absolute;
  top: 0;
  left: 0;
  scale: 1.01;
}

.img2 .scenes.scene1 {
  background-image: url("../../gif.2be81173.gif");
  background-position: 20%;
  background-size: 100%;
  width: 175px;
}

.img2 .scenes.scene2 {
  background-image: url("../../gif2.1b5a63b5.gif");
  background-position: 20%;
  background-size: 120%;
  width: 175px;
}

.hidden {
  display: none;
}

.steps {
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100vw;
  margin-top: 0;
  display: flex;
}

.steps div {
  background-color: #40002d4d;
  width: 100%;
  height: 20px;
  margin: 0;
  display: block;
  position: relative;
}

.steps div.active {
  background-color: #a50174;
}

.steps div.active.step_no {
  background-color: #40002d4d;
}

.steps div.active.step_no:before {
  content: "";
  background-color: #a50174;
  width: 0%;
  height: 100%;
  animation: .2s both fill-progress-bar;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes fill-progress-bar {
  0% {
    width: 0%;
  }

  60% {
    width: 115%;
  }

  85% {
    width: 95%;
  }

  100% {
    width: 100%;
  }
}

#fx, #bg {
  z-index: -1;
  object-fit: cover;
  opacity: .2;
  mix-blend-mode: hard-light;
  width: 100vw;
  height: 100vh;
  transition: all .3s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
}

#bg {
  filter: blur(30px);
  opacity: .4;
}

#bg.v2 {
  filter: blur(5px);
}

main {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
  display: flex;
  position: relative;
}

main .box {
  text-align: center;
  color: #fff;
  background-color: #111;
  border-radius: 1.5em;
  width: 92%;
  min-width: 265px;
  max-width: 570px;
  margin: 0 20px;
  padding: 15px 20px;
  font-size: 18px;
  box-shadow: 0 0 15px #0006;
}

main .box h1 {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 21px;
  font-weight: 700;
}

main .box h2, main .box h3 {
  font-size: 17px;
  font-weight: 300;
}

main .box form {
  text-align: left;
  min-height: 0;
  margin: 40px 0;
}

@media only screen and (width >= 769px) {
  main .box {
    padding: 35px 40px;
  }

  main .box h1 {
    margin-bottom: 15px;
    font-size: 26px;
  }

  main .box h2, main .box h3 {
    font-size: 19px;
    font-weight: 300;
  }
}

.m-wrapper {
  z-index: 5;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (width <= 768px) {
  .m-wrapper {
    justify-content: flex-start;
    margin-top: 5vh;
  }

  main {
    width: 100%;
  }
}

@media only screen and (width >= 769px) {
  main {
    aspect-ratio: 11 / 16;
  }
}

.puff-in-center {
  animation: 80ms cubic-bezier(.47, 0, .745, .715) both puff-in-center;
}

@keyframes puff-in-center {
  0% {
    opacity: .1;
    transform: scale(.92);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.w1 {
  outline: 3px solid #23124dd8;
  padding: 12px;
}

.w2 {
  color: #df319c8a;
  text-transform: uppercase;
  padding: 8px 0;
  font-size: 19px;
  font-weight: 400;
}

.larger {
  font-size: 36px !important;
}
/*# sourceMappingURL=index.96c47aa3.css.map */
