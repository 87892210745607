$btn-color: rgb(151, 11, 132);
$btn-color-skip: rgba(71, 66, 80, 0.164);

.btn-wrapper {
    display: grid;
    grid-auto-flow: column;
    gap: 30px;

    @media only screen and (max-width: 768px) {
        /* For mobile phones: */
        gap: 20px;
    }
}

.btn {
    position: relative;
    margin: 50px 0;
    box-shadow: 0px 10px 35px -10px $btn-color;
    background-color: lighten($btn-color, 10%);
    border-radius: 15px;
    // border:1px solid darken($btn-color, 10%);
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-size: 21px;
    font-weight: 700;
    letter-spacing: 0.05rem;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-decoration: none;
    text-shadow: 0px 1px 0px darken($btn-color, 10%);
    margin: 0;
    margin-top: 20px;
    outline: 0 !important;

    &.cta-btn::before {
        content: '';
        background: linear-gradient(45deg, #5600ff, #7a00ff, #0095ff, #ff0080, #ee05e2, #002bff, #7a00ff, #7800c4, #5d00c4);
        position: absolute;
        top: -1px;
        left: -1px;
        background-size: 400%;
        z-index: -1;
        filter: blur(5px);
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        animation: glowing 40s linear infinite;
        opacity: 1;
        transition: opacity .4s ease-in-out;
        border-radius: 20px;

        @keyframes glowing {
            0% {
                background-position: 0 0;
            }

            50% {
                background-position: 400% 0;
            }

            100% {
                background-position: 0 0;
            }
        }
    }



    &:hover {
        background-color: lighten($btn-color, 20%);
    }

    &:active,
    &:focus {
        position: relative;
        top: 1px;
        outline: 0;
    }

    &.skip {
        box-shadow: 0px 10px 35px -10px $btn-color-skip;
        background-color: lighten($btn-color-skip, 10%);
        text-shadow: 0px 1px 0px darken($btn-color-skip, 10%);

        &:hover {
            background-color: lighten($btn-color-skip, 20%);
        }
    }
}

// @media only screen and (min-width: 769px) {
// 	/* For Desktop: */
// 	.btn {
// 		width: auto;
// 		padding: 20px 45px;
// 	}
// }